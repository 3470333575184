<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
// import Datatable from "@/router/layouts/table-datatable.vue";

export default {
    page: {
        title: "Buat Surat Tugas Rapat Pleno",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        // Datatable,
    },
    data() {
        let sortOrders = []; // select sortOrder

        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "-" },
            { width: "auto", label: "Nomor Asesmen", name: "-" },
            { width: "auto", label: "Asesi", name: "-" },
            { width: "auto", label: "Nama Skema Sertifikasi", name: "-" },
            { width: "200px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });
        return {
            title: "Buat Surat Tugas Rapat Pleno",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },    
                {
                    text: "Rapat Pleno",
                    active: "/pasca-asesmen/daftar-banding-admin",	
                },
                {
                    text: "Surat Tugas Rapat Pleno",
                    active: true,
                },
                
            ],
            // Catch Error Axios
            axiosCatchError: null,
            id_rapat_pleno: null,
            // variabel referensi
            optionsMenuParent: [],
            tanggal_surat_tugas : new Date().toISOString().slice(0, 10),
            perihal_surat_tugas : "Surat Tugas Jadwal Rapat Pleno",
            nomor_surat_tugas : null,
            urutan_nomor: null,
            tuk: null,

            //modal
            showModalSimpan: false,
              // variable Page Table
            columns: columns,
            sortKey: "", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",
            optionsAsesmen: [],
            table_data:[
                {
                    'id_asesmen': null,
                    'no_asesmen': '',
                    'nomor_surat_banding': '',
                    'metode_id' : null,
                    'metode_nama' : null,
                    'skema_id' : null,
                    'nama_skema_sertifikasi': '',
                    'id_rapat_banding': null,
                    'no_rapat_banding': null,
                    'asesi': [],
                }
            ],
            preview_surat_penetapan: null,
            next_status: null,

        };
    },
    mounted() {
        this.getNomorSuratTugas();
        this.getAsesemenFinal();
    },
    methods: {

        addTableData() {
            this.table_data.push({
                    'id_asesmen': null,
                    'no_asesmen': '',
                    'nomor_surat_banding': '',
                    'metode_id' : null,
                    'metode_nama' : null,
                    'skema_id' : null,
                    'nama_skema_sertifikasi': '',
                    'id_rapat_banding': null,
                    'no_rapat_banding': null,
                    'asesi': [],
            });
        },
        removeTableData(index) {
            this.table_data.splice(index, 1);
        },
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method : "post",	
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-pleno/store-pleno",
                data:  {
                    'tanggal_surat_tugas': self.tanggal_surat_tugas,
                    'nomor_surat_tugas': self.nomor_surat_tugas,
                    'perihal': self.perihal_surat_tugas,
                    'tuk_nama': self.tuk,
                    'data_asesmen': self.table_data,
                    'urutan_nomor': self.urutan_nomor,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    let res = response.data.data;
                    self.preview_surat_penetapan = res.pdf;
                    self.id_rapat_pleno = res.data_rapat_pleno.rapat_pleno.id;
                    self.next_status = res.data_rapat_pleno.next_status;
                    Swal.close();
                })
        },
        getNomorSuratTugas() {
            let self = this;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-pleno/get-nomor-pleno", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    var next_nomor = response?.data?.data?.next_urutan;
                    var currentDate = new Date();
                    self.nomor_surat_tugas = next_nomor + "/RP/LSP/" + self.convertToRoman(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
                    self.urutan_nomor = next_nomor;
                })
                .catch((e) => {
                    console.log(e);
                    // this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
        convertToRoman(num) {
            var roman = { M: 1000, CM: 900, D: 500, CD: 400,C: 100,XC: 90, L: 50,XL: 40, X: 10, IX: 9,  V: 5, IV: 4, I: 1 };
            var str = '';
            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },
        getAsesemenFinal(){
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-asesmen-final",
                headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    self.optionsAsesmen = response.data.data.referensi;
                } else {
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
            console.log(error);
            });
        },
        changeSelectAsesmen(index){
            // insert data to table_data
            let self = this;
            var data_asesmen = self.optionsAsesmen.find(x => x.nomor_asesmen === self.table_data[index].no_asesmen);
            if (data_asesmen == undefined) {
                var data_banding = self.optionsAsesmen.find(x => x.rapat_banding_nomor === self.table_data[index].no_asesmen);
                self.table_data[index].id_rapat_banding = data_banding.id;
                self.table_data[index].no_rapat_banding = data_banding.rapat_banding_nomor;
                self.table_data[index].metode_id =  data_banding.rapat_banding_detail[0].metode_id;
                self.table_data[index].metode_nama =  data_banding.rapat_banding_detail[0].metode_nama;
                self.table_data[index].skema_id = data_banding.rapat_banding_detail[0].skema_id
                self.table_data[index].nama_skema_sertifikasi = data_banding.rapat_banding_detail[0].skema_nama ?? 'tidak ada data';
                self.table_data[index].asesi = data_banding.rapat_banding_asesi;
            }
            else{
                self.table_data[index].id_asesmen = data_asesmen.id;
                self.table_data[index].metode_id = data_asesmen.metode_id;
                self.table_data[index].metode_nama = data_asesmen.metode_nama;
                self.table_data[index].no_asesmen = data_asesmen.nomor_asesmen;
                self.table_data[index].skema_id = data_asesmen.pra_asesmen?.skema_id ?? null;
                self.table_data[index].nama_skema_sertifikasi = data_asesmen?.skema_nama ?? 'tidak ada data';        
                self.table_data[index].asesi = data_asesmen.asesmen_asesi;
            }
           
        },
        simpanSurat(){
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "rapat-pleno/ubah-status",
                data: {
                    id: self.id_rapat_pleno,
                    next_status : self.next_status.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman list rapat pleno",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            // close all modal
                            self.$router.push({ name: "daftar-pleno-admin" });
                        }
                    });
                })
                .catch(function (error) {
                    // this.axiosCatchError = e.response;
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    console.log(error);
                    self.axiosCatchError = error.response.data.data.error;
                });
        },
        
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' ||
                                    axiosCatchError instanceof String
                                    ">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <h3>Formulir Surat Tugas Asesor Pleno</h3>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group col-md-12">
                                        <label for="tipe_menu">Tanggal Surat Tugas <span class="required-field"></span></label>
                                        <input type="date" v-model="tanggal_surat_tugas" class="form-control">
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="tipe_menu">Nomor Surat Tugas <span class="required-field"></span></label>
                                        <input type="text" v-model="nomor_surat_tugas" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group col-md-12">
                                        <label for="tipe_menu">Perihal <span class="required-field"></span></label>
                                        <input type="text" v-model="perihal_surat_tugas" class="form-control" readonly>
                                    </div>
                                    <!-- <div class="form-group col-md-12">
                                        <label for="tipe_menu">Tempat Pelaksanaan Rapat Pleno <span class="required-field"></span></label>
                                        <input type="text" v-model="tuk" class="form-control">
                                    </div> -->
                                </div>
                                <div class="col-12 mt-4">
                                    <table class="table mb-0 table-bordered table-condensed table-hover mt-4 mb-4">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th style="width: 50px">No</th>
                                                <th>No Asesmen</th>
                                                <th class="text-center" style="width: 30%">Asesi</th>
                                                <th class="text-center" style="width: 30%">Nama Skema</th>
                                                <th class="text-center" style="width: 60px">

                                                    <div class="btn btn-success btn-sm" v-on:click="addTableData"><i class="bx bx-plus"></i></div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr v-if="table_data.length == 0">
                                                <td class="text-center" colspan="8">Data Tidak Tersedia</td>
                                            </tr>
                                            <tr v-else v-for="(data, index) in table_data" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>
                                                    <select class="form-select select2 select2-hidden-accessible" id="statusTable" v-model="data.no_asesmen" @click="changeSelectAsesmen(index)">
                                                        <option value="">Pilih Asesmen</option>
                                                        <option v-for="(row_data, key_data) in optionsAsesmen" :key="key_data" :value="row_data.nomor_asesmen ? row_data.nomor_asesmen : row_data.rapat_banding_nomor">
                                                            {{ row_data.nomor_asesmen ?  row_data.nomor_asesmen : row_data.rapat_banding_nomor  }} - {{ row_data.nomor_asesmen ? '( ASESMEN )' : '( RAPAT BANDING )' }}
                                                        </option>
                                                    </select>
                                                </td> 
                                                <td>
                                                    <template v-if="data.asesi.length == 0">
                                                        <span class="badge badge-danger">Data tidak tersedia</span>
                                                    </template>

                                                    <ol v-else>
                                                        <li v-for="(asesi, index) in data.asesi" :key="index">
                                                            {{ asesi.asesi_nama }}
                                                        </li>
                                                    </ol>
                                                </td>
                                                <td> {{ data.nama_skema_sertifikasi }}</td>
                                                <td class="text-center">
                                                    <button type="button" class="btn btn-danger btn-sm mx-2" v-on:click="removeTableData(index)">
                                                        <i class="fa fa-minus"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-12 col-md-6">
                                        <div class="text-end">
                                            <b-button type="submit" variant="primary" class="m-1"><i
                                                    class="fa fa-refresh"></i>
                                                Generate Surat Penetapan</b-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="alert alert-info mt-3" v-if="!preview_surat_penetapan"><i class="fas fa-info"></i> Generate Surat Penetapan Terlebih Dahulu</div>

                                <div class="row" v-if="preview_surat_penetapan">
                                    <h3>Preview Surat Tugas Rapat Pleno</h3>
                                    <div class="row p-4">
                                        <div class="card" style="outline-style: outset">
                                            <div class="card-body">
                                                <iframe v-bind:src="preview_surat_penetapan" width="100%" height="400"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer" v-if="preview_surat_penetapan">
                            <div class="row">
                                <div class="col-md-12 col-md-6">
                                    <div class="text-end">
                                        <b-button @click="simpanSurat" variant="primary" class="m-1"><i
                                                class="fa fa-save"></i>
                                            Simpan
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
